import * as React from "react";
import { useCookies } from "react-cookie";

import axios from "../../api/Axio";
import * as cookie from "../../utils/cookie/Cookies";
import useAuth from "../../hooks/UseAuth";
import Box from "@mui/material/Box";
import DefaultLayout from "../../layouts/DefaultLayout";

const LOGIN_URL = "/login/loginAction"

const Login = () => {
  const [errMsg, setErrMsg] = React.useState("");

  const [userId, setUserID] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passYn, setPassYn] = React.useState(0);

  const [passwordError, setpasswordError] = React.useState("");
  const [userIDError, setUserIDError] = React.useState("");
  const [resultError, setresultError] = React.useState("");
  const [saveID, setsaveID] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["saveId"]);

  React.useEffect(() => {
    console.log("LoginPage render ...");
    localStorage.setItem("access_token", "");

    if (cookies.saveId !== undefined) {
      setUserID(cookies.saveId);
      console.log("saveId : " + cookies.saveId);
      setsaveID(true);
    }

    cookie.removeCookie("access_token");
    cookie.removeCookie("memberNo");
    cookie.removeCookie("memberId");
    cookie.removeCookie("memberName");
    cookie.removeCookie("gubun");
    cookie.removeCookie("authList");
  }, [cookies.saveId]);

  const handleOnChange = (e) => {
    setsaveID(e.target.checked);
    if (e.target.checked) {
      console.log("set");
      setCookie("saveId", userId, { maxAge: 2000 });
    } else {
      console.log("remove");
      removeCookie("saveId");
    }
  };

  const handleValidation = (e) => {
    e.preventDefault();
    let formIsValid = true;
    console.log("userId :    " + userId);
    console.log("password :    " + password);

    if (userId.length < 1) {
      formIsValid = false;
      alert("아이디를 입력해주세요.");
      return false;
    } else {
      setUserIDError("");
      formIsValid = true;
    }

    if (password.length < 1) {
      formIsValid = false;
      alert("비밀번호를 입력해주세요.");
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }
    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let checkVal = handleValidation(event);
    const data = {
      memberId: userId,
      passwd: password
    }
    let ff = passYn + 1;

    if (passYn >= 5) {
      await axios.post("/login/loginFailure", data).then((response) => {
        console.log("RES = %j", response);
        alert("5회이상 로그인에 실패하여 사용정지 되었습니다.\n담당자에게 문의해주세요.");
      }).catch((err) => {
        console.log(ff);
        setPassYn(ff);
        alert("로그인에 실패했습니다.\n로그인 정보를 확인해 주세요.");
        console.log(err);
      })
    } else {
      if (checkVal === true) {
        setresultError("");
        // console.log(data)
        await axios.post(LOGIN_URL, data).then((response) => {
          console.log("로그인", response.data);
          let rsCode = response?.data?.code;
          let rsMassage = response?.data?.message;
          let token = response?.data?.memberToken;
          let memberNo = response?.data?.user?.memberNo;
          let memberId = response?.data?.user?.memberId;
          let memberName = response?.data?.user?.memberName;
          let gubun = response?.data?.user?.gubun;
          let auth = response?.data?.auth;
          console.log(auth);
          console.log(token);
          if (rsCode == 200) {
            if (token) {
              setresultError("");
              const access_token = token;
              const access_auth = auth;

              // const expires = Math.floor(Date.now() / 1000) + (60 * 60);
              cookie.setCookie("memberNo", memberNo);
              cookie.setCookie("memberId", memberId);
              cookie.setCookie("memberName", memberName);
              cookie.setCookie("gubun", gubun);
              cookie.setCookie("access_token", access_token, {
                path: "/",
                // expires: expires,
              })
              window.open(
                  "/messenger/list",
                  '_blank', // 새 창을 새 탭에서 열기
                  'width=386,height=602' // 새 창의 크기 설정 (선택 사항)
              );
              window.location.href = "/dashboard"
            } else {
              setPassYn(ff);
              alert("로그인에 실패했습니다.\n로그인 정보를 확인해 주세요.");
              console.log(response);
            }
          } else if (rsCode == 403) {
            setPassYn(ff);
            alert(rsMassage);
          } else if (rsCode == 601) {
            alert("Session Expires : 세션이 만료되었습니다.");
          } else if (rsCode == 602) {
            alert("IP blocking: 차단된 아이피입니다.");
          } else {
            setPassYn(ff);
            alert("로그인에 실패했습니다.\n로그인 정보를 확인해 주세요.");
            console.log(response);
          }


        }).catch((err) => {
          console.log(ff);
          setPassYn(ff);
          alert("로그인에 실패했습니다.\n로그인 정보를 확인해 주세요.");
          console.log(err);
        })
      }
    }


    console.log(ff);
  };

  return (
    <div id="wrapper">
      <div className="login_box_wrap">
        <div className="login_box">
          <div className="login_input">
            <div className="login_input_in">
              <Box component="form" onSubmit={handleSubmit}>
                <h1>
                  <img src="img/login_logo.png" alt="" />
                </h1>
                <input
                  type="text"
                  className="input mar"
                  id="userId"
                  name="userId"
                  autoFocus
                  onChange={(e) => setUserID(e.target.value)}
                  placeholder="아이디를 입력해 주세요."
                  style={{ imeMode: 'auto' }} />
                <input
                  type="password"
                  className="input"
                  id="password"
                  name="password"
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  onFocus={() => {
                    let body = document.getElementById("body");
                    body.setAttribute('onpaste', "return false");
                  }}
                  onBlur={() => {
                    let body = document.getElementById("body");
                    body.setAttribute('onpaste', "return true");
                  }}
                  placeholder="패스워드를 입력해주세요."
                />
                <div className="save">
                  <div className="icheck">
                    <input
                      type="checkbox"
                      name="z1"
                      id="saveid"
                      onChange={handleOnChange}
                      value={saveID}
                    />
                    <label htmlFor="saveid">아이디 저장</label>
                  </div>
                </div>
                <div className="submit_btn">
                  <input type="submit" className="submit" value="로그인" />
                </div>
              </Box>
            </div>
          </div>
          <div className="login_img">
            <img src="img/login_img.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
